<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>
          <b-form-group
            id="input-group-registration-number"
            :label="'NIK Istri'"
            label-for="input-registration-number"
          >
            <b-form-input
              id="input-registration-number"
              v-model="form.registration_number"
              :placeholder="'NIK Istri'"
            ></b-form-input>
            <small class="text-danger">{{ error.registration_number }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-name"
            label="Nama Istri:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Istri"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-birt-date"
            label="Tanggal Lahir Istri:"
            label-for="input-birt-date"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-birt-date"
                  v-model="form.birt_date"
                  label="Tanggal Lahir Istri:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.birt_date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.birt_date }}</small>
          </b-form-group>

          <b-form-group id="input-group-blood-type">
            <label for="input-blood-type-id">Golongan Darah Istri:</label>
            <treeselect
              v-model="form.blood_type_id"
              :multiple="false"
              :options="blood_types"
              placeholder="Pilih Golongan Darah Istri"
            />
            <small class="text-danger">{{ error.blood_type_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-husband-name"
            label="Nama Suami:"
            label-for="input-husband-name"
          >
            <b-form-input
              id="input-husband-name"
              v-model="form.husband_name"
              placeholder="Nama Suami"
            ></b-form-input>
            <small class="text-danger">{{ error.husband_name }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-husband-registration-number"
            :label="'NIK Suami'"
            label-for="input-husband-registration-number"
          >
            <b-form-input
              id="input-husband-registration-number"
              v-model="form.husband_registration_number"
              :placeholder="'NIK Suami'"
            ></b-form-input>
            <small class="text-danger">{{
              error.husband_registration_number
            }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-husband-birt-date"
            label="Tanggal Lahir Suami:"
            label-for="input-husband-birt-date"
          >
            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-husband-birt-date"
                  v-model="form.husband_birt_date"
                  label="Tanggal Lahir Suami:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.husband_birt_date"
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.husband_birt_date }}</small>
          </b-form-group>

          <b-form-group id="input-group-husband-blood-type">
            <label for="input-husband-blood-type-id"
              >Golongan Darah Suami:</label
            >
            <treeselect
              v-model="form.husband_blood_type_id"
              :multiple="false"
              :options="blood_types"
              placeholder="Pilih Golongan Darah Suami"
            />
            <small class="text-danger">{{ error.husband_blood_type_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-is-pregnant"
            label="Sedang Hamil:"
            label-for="input-is-pregnant"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-is-pregnant"
              v-model="form.is_pregnant"
              :aria-describedby="ariaDescribedby"
              name="input-group-is-pregnant"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_pregnant }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-weight"
            label="Berat Badan Ibu Hamil (Kg):"
            label-for="input-weight"
            v-if="form.is_pregnant == 1"
          >
            <b-form-input
              id="input-weight"
              v-model="form.weight"
              placeholder="Berat Badan Ibu Hamil"
            ></b-form-input>
            <small class="text-danger">{{ error.weight }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-last_menstruation-date"
            label="Tanggal Terakhir Haid:"
            label-for="input-last_menstruation-date"
            v-if="form.is_pregnant == 1"
          >
            <v-menu
              v-model="menu5"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-last_menstruation-date"
                  v-model="form.last_menstruation"
                  label="Tanggal Terakhir Haid:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.last_menstruation"
                @input="menu5 = false"
                @change="setHPL"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.last_menstruation }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-hpl"
            label="Hari Perkiraan Lahir (HPL):"
            label-for="input-hpl"
            v-if="form.is_pregnant == 1"
          >
            <v-menu
              v-model="menu6"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-hpl"
                  v-model="form.hpl"
                  label="Hari Perkiraan Lahir (HPL):"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.hpl"
                @input="menu6 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.hpl }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-delivery-date"
            label="Tanggal Melahirkan:"
            label-for="input-delivery-date"
            v-if="form.is_pregnant == 1"
          >
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-delivery-date"
                  v-model="form.delivery_date"
                  label="Tanggal Melahirkan:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.delivery_date"
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.delivery_date }}</small>
          </b-form-group>

          <b-form-group id="input-group-name" v-if="form.is_pregnant == 1">
            <label for="input-address"
              >Tanggal Meninggal: <em class="text-muted">opsional</em></label
            >
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="form.date"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  id="input-trigger-modal-patient"
                  v-model="form.die_date"
                  placeholder="Tanggal Meninggal"
                >
                </b-form-input>
              </template>

              <v-date-picker v-if="modal2" v-model="form.die_date" locale="id">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(form.die_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <small class="text-danger">{{ error.die_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            v-if="form.is_pregnant == 1"
          >
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-2"
            label="Mendapat KIA:"
            label-for="input-p-2"
            v-slot="{ ariaDescribedby }"
            v-if="form.is_pregnant == 1"
          >
            <b-form-radio-group
              id="input-group-p-2"
              v-model="form.is_kia"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-2"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_kia }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-14"
            label="Mendapat Tablet Besi:"
            label-for="input-p-14"
            v-slot="{ ariaDescribedby }"
            v-if="form.is_pregnant == 1"
          >
            <b-form-radio-group
              id="input-group-p-14"
              v-model="form.p14"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-14"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.p14 }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-immunization-list"
            label="Mendapat Imunisasi:"
            label-for="input-immunization-list"
            v-if="form.is_pregnant == 1"
          >
            <treeselect
              v-model="form.immunization_type_id"
              :multiple="false"
              placeholder="Mendapat Imunisasi"
              :options="immunization_types"
            />
            <small class="text-danger">{{ error.immunization_type_id }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-p-is-breestfeeding"
            label="Sedang Menyusui:"
            label-for="input-p-is-breestfeeding"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="input-group-p-is-breestfeeding"
              v-model="form.is_breestfeeding"
              :aria-describedby="ariaDescribedby"
              name="input-group-p-is-breestfeeding"
            >
              <b-form-radio value="1">Ya</b-form-radio>
              <b-form-radio value="0">Tidak</b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ error.is_breestfeeding }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-acceptor-type"
            label="Jenis Akseptor yang digunakan:"
            label-for="input-acceptor-type"
          >
            <treeselect
              v-model="form.acceptor_type_id"
              :multiple="false"
              placeholder="Jenis Akseptor"
              :options="acceptor_types"
            />
            <small class="text-danger">{{ error.acceptor_type_id }}</small>
          </b-form-group>

          <!-- <b-form-group id="input-group-address">
            <label for="input-address"
              >Alamat Rumah: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat Rumah"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group> -->
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/mothers')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
  },
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        registration_number: "",
        address: "",
        blood_type_id: "",
        husband_blood_type_id: "",
        immunization_type_id: "",
        acceptor_type_id: "",
        die_date: "",
        description: "",
        weight: "",
        husband_name: "",
        birt_date: "",
        husband_birt_date: "",
        husband_registration_number: "",
        delivery_date: "",
        is_kia: "",
        p14: "",
        last_menstruation: "",
        hpl: "",
      },
      blood_types: [],
      immunization_types: [],
      acceptor_types: [],
      current_photo: "/img/default/default-user.svg",
      default_photo: "/img/default/default-user.svg",
      majors: [],
      // other
      passwordIsVisible: "password",
      // date
      time: null,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      modal2: false,
      picker: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    async getBloodTypeOption() {
      let response = await module.setTreeSelect("api/blood-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.blood_types = response.data;
        this.blood_types.unshift({
          label: "Pilih Golongan Darah",
          id: 0,
          isDisabled: true,
        });
      }
    },

    async getImmunizationTypeOption() {
      let response = await module.setTreeSelect(
        "api/immunization-types",
        "?is_general=2"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.immunization_types = response.data;
        this.immunization_types.unshift({
          label: "Jenis Imunisasi",
          id: 0,
          isDisabled: true,
        });
        this.immunization_types.push({
          label: "Semua",
          id: -1,
        });
      }
    },

    async getAcceptorTypeOption() {
      let response = await module.setTreeSelect("api/acceptor-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.acceptor_types = response.data;
        this.acceptor_types.unshift({
          label: "Jenis Akseptor",
          id: 0,
          isDisabled: true,
        });
        this.acceptor_types.push({
          label: "Lain-lain",
          id: -1,
        });
      }
    },

    setHPL(evt) {
      let hpht = new Date(evt);
      let hpl = new Date();

      hpl.setDate(hpht.getDate() + 7);
      hpl.setDate(hpl.getDate() - 90);
      hpl.setDate(hpl.getDate() + 365);

      console.log("HPHT: " + hpht);
      console.log("HPL: " + hpl.toISOString().substr(0, 10));

      this.form.hpl = hpl.toISOString().substr(0, 10)
      // console.log(hpl);
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/mothers");
      }
    },
  },
  mounted() {
    this.getBloodTypeOption();
    this.getImmunizationTypeOption();
    this.getAcceptorTypeOption();
  },
};
</script>